import { SET_ORDER, SET_BOXES } from "./order.types";
import { IOrder } from "../../models/order";
import IBox from "../../models/box";

export interface SetOrderAction {type: typeof SET_ORDER, data:IOrder}
export interface SetBoxesAction {type: typeof SET_BOXES, data:IBox}

export const orderCreator = {
    setOrder: (data:IOrder | null) => ({ type: SET_ORDER, data: data } as SetOrderAction),
    setBoxes: (data:IBox | null) => ({ type: SET_BOXES, data: data } as SetBoxesAction)
};
