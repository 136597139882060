export enum RouteViewEnum{
    configurations = 0,
    orderAlreadyCollected = 1.1,
    orderExpired = 1.2,
    orderNotAvailable = 1.3,
    welcomePage = 1,
    thankYouPage = 2,
    collectScreen = 3,
    notification = 4,
    authentificationScreen = 5,
    terminalQrCodeScanScreen = 6
}
