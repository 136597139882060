import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';

import './custom.scss'
import OrderExpiredComponent from './components/order-expired/order-expired';
import OrderNotAvailableComponent from './components/order-not-available/order-not-available';
import ThankYouComponent from './components/thank-you/thank-you.component';
import WelcomeComponent from './components/welcome/welcome.component';
import ScanQrCodeComponent from './components/scan-qr-code/scan-qr-code.component';
import { connect } from 'react-redux';
import  * as GlobalActions from './redux/global/global.action';
import  * as OrderActions from './redux/order/order.action';
import AuthentificateComponent from './components/authentificate/authentificate.component';
import { withRouter } from "react-router";
import firstPageComponent from './components/first-page/first-page.component';
import WelcomeGuard from './guards/welcome.guard';
import AuthentificateGuard from './guards/authentificate.guard';
import ScanQrCodeGuard from './guards/scan-qr-code.guard';
import CollectGuard from './guards/collect.guard';
import CollectComponent from './components/collect/collect.component';
import CSS from 'csstype';
import errorComponent from './components/error.component.tsx/error.component';
import ApiService from './services/api.service';
// type StateProps =
//     typeof GlobalActions.messageCreator &
//     RouteComponentProps<{}>



class App extends React.PureComponent<any,any> {

    componentDidMount(): void {
        window.onbeforeunload = function(e:any) {
            if(ApiService.isProcessStarted)
                ApiService.finishCollectProcess();
        };
    }
    render() {
        let pageLoader:CSS.Properties = {};

        if(!this.props.showFullPreloader)
            pageLoader.display='none';

        // change body background globally.
        document.body.style.backgroundColor = this.props.configurations.backgroundColor;

        return (
        <Layout>
            <div  className="base-loader" style={pageLoader}>
                <div  className="spinner-grow" style={pageLoader}></div>
            </div>
            <Route exact path='/order-not-available' component={OrderNotAvailableComponent} />
            <Route exact path='/order-expired' component={OrderExpiredComponent} />
            <WelcomeGuard exact path='/welcome' order={this.props.order} component={WelcomeComponent} isPreview={this.props.isPreview}/>
            <AuthentificateGuard  exact path='/authentificate'  order={this.props.order} authConfigurations={this.props.authConfigurations} component={AuthentificateComponent} isPreview={this.props.isPreview} />
            <Route exact path='/thank-you' component={ThankYouComponent} />
            <ScanQrCodeGuard exact path='/scan-qr-code'  order={this.props.order} authConfigurations={this.props.authConfigurations} component={ScanQrCodeComponent} isPreview={this.props.isPreview}/>
            <Route path='/:code/:tenantId?/:pageType?' component={firstPageComponent}  />
            <CollectGuard exact path='/collect' component={CollectComponent}  order={this.props.order} authConfigurations={this.props.authConfigurations} isQrCodeValid={this.props.isQrCodeValid} isLocationValid={this.props.isLocationValid} isPreview={this.props.isPreview} />
            <Route path='/error' component={errorComponent} />
        </Layout>
        );
};
}

const mapStateToProps = (state:any) => {
    return {
        showFullPreloader: state.globalReducer.fullLoading,
        configurations : state.globalReducer.configurations,
        authConfigurations : state.globalReducer.authConfigurations,
        order : state.orderReducer.order,
        isQrCodeValid : state.globalReducer.isQrCodeValid,
        isLocationValid : state.globalReducer.isLocationValid,
        isPreview : state.globalReducer.isPreview
    };
};

const appRedux = connect(
    mapStateToProps,
    Object.assign({}, GlobalActions.messageCreator
        ,GlobalActions.configurationCreator
        ,OrderActions.orderCreator)
    // mapDispatchToProps
)(App);

export default withRouter(appRedux);
