export interface IOrder {
   orderNumber:string | null,
   address:string | null,
   status:OrderStatus | null,
   returnAfterTime:Date | null,
   hasAdditionalItems: boolean,
   hasPendingPackages: boolean
}

export enum OrderStatus {
   Pending = 0,
   Loaded = 1,
   CollectingStartedInLocker = 2,
   CollectingStartedAtAttendant = 3,
   Collected = 4,
   Removed = 5,
   CompletelyLoaded = 6
}
