import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './App.store';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Router } from 'react-router';
import { history } from './helpers/history.helper';
import {ai} from "./services/telemetry.service";
ai.initialize({history: history},process.env.NODE_ENV==='production'?false:true);

const store = configureStore({});

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root'));

registerServiceWorker();
