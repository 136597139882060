import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import { GlobalState } from '../../models/global-state';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import  * as GlobalActions from '../../redux/global/global.action';
import  * as OrderActions from '../../redux/order/order.action';
import OrderState from '../../models/order.state';
import { history } from '../../helpers/history.helper';
import ApiService from '../../services/api.service';
import i18n from 'i18next';
import { RouteViewEnum } from '../../enums/route-view.enum';



type StateProps =
    GlobalState & OrderState & WithTranslation &
    typeof GlobalActions.messageCreator & typeof OrderActions.orderCreator &  typeof GlobalActions.configurationCreator &  typeof GlobalActions.managePreloader &
    RouteComponentProps<{}>;

 class CollectOrderComponent extends React.PureComponent<StateProps>{

    componentDidMount(){
        let { code,tenantId, pageType} : any = this.props.match.params;

        ApiService.linkCode = code;

        if (code && code !== 'undefined' && code !== 'null') {
            ApiService.order().then(data => data.json()).then(res => {
                if (!res.data || !res.data.order || !res.data.resources.configurations)
                {
                    history.push('/error');
                    return;
                }

                this.props.setOrder(res.data.order);
                this.props.setBoxes(res.data.boxes);
                this.props.setIsDmIntegrationEnabled(res.isDmIntegrationEnabled);
                // this.props.setIsHasAdditionalItemsNotificationSent(res.isHasAdditionalItemsNotificationSent);
                // this.props.setIsPendingOrderNotificationSent(res.isPendingOrderNotificationSent);

                let defaultLang: string = '';
                let obj: any = {};

                res.data.resources.translations.forEach((lng:any) => {
                    if (!defaultLang)
                        defaultLang = lng.languageId;

                    obj[lng.languageId] = { translation: lng.translations }
                });

                i18n.init({
                    lng: defaultLang,
                    resources: obj,
                    lowerCaseLng: true
                });

                this.props.setConfigurations(res.data.resources.configurations);
                this.props.setAuthConfigurations(res.data.resources.authConfigurations);
                this.props.setTranslations(obj);

                if(ApiService.linkCode)
                    this.props.setLinkCode(ApiService.linkCode);

                this.props.hideFullLoader();

                if (res.errorCode === 1 || (res.data.boxes.length === 0 && (res.data.order.status === 4 || res.data.order.status === 5))) {
                    history.push('/order-not-available');
                    return;
                } else {
                    history.push('/welcome');
                }
            }).catch(err => {
                console.log(err);
                history.push('/error');
            }).finally(() => this.setState({...this.state,isLoaderOn:false }));
        }else if(tenantId && pageType){
                ApiService.getAll(tenantId).then(data => data.json()).then(
                    res => {
                        if( !res.configurations )
                        {
                                history.push('/error');
                                return;
                         }

                        let defaultLang:string = '';
                        let obj:any = {};
                        res.translations.forEach((lng:any) => {
                            if (!defaultLang)
                                defaultLang = lng.languageId;

                            obj[lng.languageId] = {translation: lng.translations}
                        });

                        i18n.init({
                            lng: defaultLang,
                            resources: obj,
                            lowerCaseLng: true
                        });

                        this.props.setConfigurations(res.configurations);
                        this.props.setAuthConfigurations(res.authConfigurations);
                        this.props.setTranslations(obj);
                        this.props.setPreviewTenantId(tenantId);

                        if(pageType == RouteViewEnum.welcomePage)
                            history.push('/welcome');
                        else if(pageType == RouteViewEnum.orderAlreadyCollected)
                        {
                            this.props.showSuccessMessage('WebLinkWelcomePage_MessageIfOrderIsAlreadyCollected');
                            history.push('/welcome');
                        }
                        else if(pageType == RouteViewEnum.orderExpired)
                            history.push('/order-expired');
                        else if(pageType == RouteViewEnum.orderNotAvailable)
                            history.push('/order-not-available');
                        else if(pageType == RouteViewEnum.authentificationScreen)
                            history.push('/authentificate');
                        else if(pageType == RouteViewEnum.terminalQrCodeScanScreen)
                            history.push('/scan-qr-code');
                        else if(pageType == RouteViewEnum.collectScreen)
                            history.push('/collect');
                        else if(pageType == RouteViewEnum.thankYouPage)
                            history.push('/thank-you');
                        else {
                            history.push('/error');
                        }

                        this.props.hideFullLoader();
                    }
                ).catch((err) => {
                    console.log(err);
                }).finally(() => {
                    // this.props.showPreloader(false);
                });
            }
    }

    render(){
        return <div></div>
    }
}

const collectOrderComponent = connect(
    (state: any) => Object.assign({}, state.globalReducer, state.orderReducer),
    Object.assign({}, GlobalActions.messageCreator
        ,GlobalActions.configurationCreator
        ,GlobalActions.managePreloader
        ,OrderActions.orderCreator)
)(CollectOrderComponent);

export default withTranslation()(collectOrderComponent);
