import React from 'react'
import {connect} from 'react-redux';
import * as GlobalActions from '../../redux/global/global.action';
import {GlobalState} from '../../models/global-state';
import OrderState from '../../models/order.state';
import {WithTranslation, withTranslation} from 'react-i18next';
import {RouteComponentProps} from 'react-router';
import OrderInfoLabelComponent from '../order-info-label/order-info-label.component';
import {IOrder, OrderStatus} from '../../models/order';
import MessageLabelComponent from '../message-label/message-label.component';
import BoxComponent from './box.component';
import CSS from 'csstype';
import {history} from '../../helpers/history.helper';
import ApiService from '../../services/api.service';
import {MessageTypeEnum} from "../../enums/message-type-enum";

interface State{
    timeout: number,
    timeoutInterval: any
}

type StateProps =
    GlobalState & OrderState & WithTranslation &
    typeof GlobalActions.messageCreator &
    RouteComponentProps<{}>;

class CollectComponent extends React.Component<StateProps,State>{
    constructor(props:any){
        super(props);
        this.state = {timeout: 0, timeoutInterval:null};
    }

    async componentDidMount() {
        this.setState({timeout: this.props.configurations.sessionTimeout});
        if (!this.props.isPreview) {
            let timeoutInterval = setInterval(() => {
                if (this.state.timeout <= 0) {
                    let {timeoutInterval} = this.state;
                    clearInterval(timeoutInterval);

                    ApiService.finishCollectProcess(true);
                    ApiService.isProcessStarted = false;
                    history.push('/thank-you');
                    return;
                }

                let {timeout} = this.state;
                this.setState({timeout: --timeout});
            }, 1000);

            this.setState({ timeoutInterval: timeoutInterval })

            // const result = await ApiService.afterCollectStarted();
            // if (!(result === true || result === false)) {
            //     history.push('/error')
            //     return
            // }

            // this.props.setIsNotificationSented(result)

        }
    }

    finishCollecting = async () => {
        this.props.clearMessage();
        if (this.props.isPreview)
            return;

        const result = await ApiService.finishCollectProcess();
        if (!result) {
            history.push('/error')
            return
        }
        ApiService.isProcessStarted = false;
        history.push('/thank-you');
    }

    lockBox = (boxNo:string) => {
        this.props.clearMessage();
        if(this.props.isPreview)
            return;

        if(!this.props.linkCode)
            return;

        let box = this.props.boxes ? this.props.boxes.find(b=>b.boxNo === boxNo) : undefined;
        if(box){
            box.inAction = true;
            ApiService.lockBox(boxNo).then(res=>{
                if(res.errorCode === 69)
                    return;
                if(!res.errorCode){
                    if(box && res.boxInfo)
                    {
                        box.isLocked = res.boxInfo.isLocked;
                        box.isCollected = !res.boxInfo.isOccupied;

                        if(!res.boxInfo.isLocked && res.boxInfo.isOpen)
                            this.props.showErrorMessage('WebLinkCollectScreen_CloseBoxDoorsNotification');
                        else{
                            box.isCollected = !res.boxInfo.isOccupied;
                        }
                    }

                    return;
                } else {
                    history.push('/error');
                }

            }).catch( err => {
            }).finally(()=>{
                if(!box)
                    return;

                box.inAction = false;
            });
        }
    }

    unlockBox = (boxNo:string) => {
        this.props.clearMessage();
        if(this.props.isPreview)
            return;
        if(!this.props.linkCode)
            return;

        let box = this.props.boxes ? this.props.boxes.find(b=>b.boxNo === boxNo) : undefined;
        if(!box)
            return;

        box.inAction = true;
        ApiService.unlockBox(boxNo).then(res=>{
            if(res.errorCode === 69)
                return;
            if (!res.errorCode) {
                if (box && res.boxInfo)
                    box.isLocked = res.boxInfo.isLocked;
                return;
            } else {
                history.push('/error');
            }
        }).catch(err => {

        }).finally(()=>{
            if(!box)
                return;

            box.inAction = false;
        });
    }

    formatTimeLeft = (time:number) => {
        // The largest round integer less than or equal to the result of time divided being by 60.
        const minutes = Math.floor(time / 60);

        // Seconds are the remainder of the time divided by 60 (modulus operator)
        let seconds:number = time % 60;
        let secondsString:string = seconds+'';
        // If the value of seconds is less than 10, then display seconds with a leading zero
        if (seconds < 10) {
            secondsString = `0${seconds}`;
        }

        // The output in MM:SS format
        return `${minutes}:${secondsString}`;
    }

    render(){
        const { t } = this.props;
        let timeOutStyle:CSS.Properties = {};
        timeOutStyle.border = `2px solid ${this.props.configurations.clockFrameColor}`;

        let { timeout } = this.state;

        const buttonStyles:CSS.Properties = {
            backgroundColor:this.props.configurations.buttonBackgroundColor,
            color: this.props.configurations.buttonFontColor
        }
        let order : IOrder = {
            orderNumber: null,
            address: null,
            status: null,
            returnAfterTime:null,
            hasAdditionalItems: false,
            hasPendingPackages: false
        };

        if(this.props.order){
            order  = {
                orderNumber: this.props.order.orderNumber,
                address: this.props.order.address,
                status: this.props.order.status,
                returnAfterTime: this.props.order.returnAfterTime,
                hasAdditionalItems: this.props.order.hasAdditionalItems,
                hasPendingPackages: this.props.order.hasPendingPackages
            }
        }
        let ageControlTypeMessage:string = 'WebLinkNotifications_AgeControlledItemsNotificationManual';
        if(this.props.ageControlType === 2 && this.props.isNotificationSented)
            ageControlTypeMessage = 'WebLinkNotifications_AgeControlledItemsNotificationIntegrated';
        else if(this.props.ageControlType === 3)
            ageControlTypeMessage = 'WebLinkNotifications_AgeControlledItemsNotificationFace';

        let askForAssistanceMessage:string = 'WebLinkNotifications_ManualAskStaffForAssistance';
        if(this.props.isNotificationSented){
            askForAssistanceMessage = 'WebLinkNotifications_IntegratedAskForAssistanceMessage';
        }

        return (
            <div className="text-center">
                <div className="body-row"><input type="text" readOnly style={timeOutStyle} className="timeout-clock" value={this.formatTimeLeft(timeout)} /></div>
                <div style={{paddingBottom:'12px', color: this.props.configurations.fontColor}}>
                    <OrderInfoLabelComponent addressText="WebLinkCollectScreen_TerminalLocation" address={order.address} orderInformationText="WebLinkCollectScreen_OrderInformation" orderNumber={order.orderNumber}/>
                </div>
                { this.props.boxes?.length === 0 && this.props.order?.status !== OrderStatus.Pending
                        ? (
                        <div className="my-4">
                            <div dangerouslySetInnerHTML={{__html:t('WebLinkNotifications_RestartCollectionNeededNotification')}}
                                 style={{color: this.props.configurations.notificationTextColor, fontSize: '14pt'}}/>
                        </div>
                    ) : ''
                }

                <div className="boxes-container" style={{paddingBottom:'12px'}}>
                    {this.props.boxes ? this.props.boxes.filter(box => box.tempIssue === false).map((box, i) => (<BoxComponent key={i}
                        isAgeControlled={box.isAgeControlled}
                        isLocked={box.isLocked}
                        isCollected={box.isCollected}
                        isOccupied={box.isOccupied}
                        boxNo={box.boxNo}
                        tempIssue={box.tempIssue}
                        unlock={this.unlockBox}
                        lock={this.lockBox}
                        configurations={this.props.configurations}
                        inAction={box.inAction}/>)) : ''}
                </div>
                {(this.props.order?.hasAdditionalItems || this.props.order?.status === OrderStatus.Pending || this.props.order?.status === OrderStatus.Loaded || this.props.order?.hasPendingPackages && this.props.order?.status === OrderStatus.CollectingStartedInLocker) &&
                (<div style={{color: this.props.configurations.fontColor}}>
                    <div className="d-inline" style={{fontSize: '14pt'}}>
                        <MessageLabelComponent message="WebLinkCollectScreen_OrderHasAdditionalItemsAtAttendant" messageType={MessageTypeEnum.default}/>
                    </div>
                    <div className="d-inline" style={{fontSize: '14pt'}}>
                        <div dangerouslySetInnerHTML={{__html:t(askForAssistanceMessage)}}
                             style={{color: this.props.configurations.notificationTextColor}}/>
                    </div>
                </div>)}
                {this.props.boxes && this.props.boxes.some(b => b.isAgeControlled) && (
                    <div style={{fontSize: '14pt'}}>
                        <div dangerouslySetInnerHTML={{__html:t(ageControlTypeMessage)}}
                             style={{color: this.props.configurations.notificationTextColor}}/>
                    </div>)}

                {this.props.boxes && this.props.boxes.some(b => b.tempIssue) && (
                    <div style={{ fontSize: '14pt' }}>
                        <div dangerouslySetInnerHTML={{__html:t('WebLinkNotifications_HasBoxWithTempIssuesNotification')}}
                             style={{color: this.props.configurations.notificationTextColor}}/>
                    </div>)}
                <div className="my-4" style={{fontSize:'14pt'}}><MessageLabelComponent message={this.props.message} messageType={this.props.messageType}/></div>
                <button onClick={this.finishCollecting} className="main-button" style={buttonStyles} >{t('WebLinkCollectScreen_FinishCollectingButton')}</button>
            </div>
        )
    }

    componentWillUnmount(){
        let {timeoutInterval} = this.state;
        clearInterval(timeoutInterval);
     }
}

const collectComponent = connect(
    (state: any) => Object.assign({}, state.globalReducer, state.orderReducer),
    Object.assign({}, GlobalActions.messageCreator
        ,GlobalActions.configurationCreator
        ,GlobalActions.messageCreator)
)(CollectComponent);

export default withTranslation()(collectComponent);
