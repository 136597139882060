import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';

interface props{
    orderInformationText:string;
    orderNumber:string | null;
    addressText:string;
    address:string | null;
}

type StateProps =
    WithTranslation & props;

 class OrderInfoLabelComponent extends React.PureComponent<StateProps>{
    componentDidMount(){

    }

    render(){
        const { t } = this.props;
        let addressText = t(this.props.addressText);
        if(this.props.address)
            addressText = addressText.replace(/\[Address\]/gi,'<b>' + this.props.address + '</b>');
        let orderInformationText = t(this.props.orderInformationText);
        if(this.props.orderNumber)
            orderInformationText = orderInformationText.replace(/\[Order\]/gi,'<b>' + this.props.orderNumber + '</b>')

        return  <div className="order-info">
                    <div dangerouslySetInnerHTML={{__html:orderInformationText}} />
                    <div dangerouslySetInnerHTML={{__html:addressText}} />
                </div>
    }

    componentWillUnmount(){

    }
}

export default withTranslation()(OrderInfoLabelComponent);
