import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import * as settings from '../appsettings.json';

class TelemetryService {
    public appInsights: any;
    public reactPlugin: any;

    constructor() {
        this.reactPlugin = new ReactPlugin();
    }

    initialize(reactPluginConfig:any, disableTelemetry:boolean) {
        // @ts-ignore
        let INSTRUMENTATION_KEY = settings.InstrumentationKey;

        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: INSTRUMENTATION_KEY,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [this.reactPlugin],
                extensionConfig: {
                    [this.reactPlugin.identifier]: reactPluginConfig
                },
                disableTelemetry: disableTelemetry
            }
        });
        this.appInsights.loadAppInsights();
    }
}

export let ai = new TelemetryService();
