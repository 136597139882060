import React from 'react'
import { GlobalState } from '../../models/global-state';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import  * as GlobalActions from '../../redux/global/global.action';
import { withTranslation, WithTranslation } from 'react-i18next';
import CSS from "csstype";

type StateProps =
    GlobalState & typeof GlobalActions.managePreloader & WithTranslation &
    RouteComponentProps<{}>;

class OrderNotAvailableComponent extends React.PureComponent<StateProps>{
    componentDidMount(){
        this.props.hideFullLoader();
    }
    render(){
        let { t } = this.props;

        let fontStyle: CSS.Properties = {
            color: this.props.configurations.fontColor
        }
        
        return <div style={fontStyle} className="text-center">
                    <h1 dangerouslySetInnerHTML={{__html:t("WebLinkWelcomePage_WelcomeText")}}/>
                    <div className="order-message" dangerouslySetInnerHTML={{__html:t("WebLinkWelcomePage_OrderIsNotAvailableForCollectionText")}}/>
        </div>
    }
}

const orderNotAvailableComponent = connect(
    (state: any) => state.globalReducer,
    GlobalActions.managePreloader
)(OrderNotAvailableComponent);

export default withTranslation()(orderNotAvailableComponent);