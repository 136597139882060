import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import { GlobalState } from '../../models/global-state';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import  * as GlobalActions from '../../redux/global/global.action';
import OrderState from '../../models/order.state';
import { history } from '../../helpers/history.helper'
import { IOrder } from '../../models/order';
import OrderInfoLabelComponent from '../order-info-label/order-info-label.component';
import MessageLabelComponent from '../message-label/message-label.component';
import ApiService from "../../services/api.service";
import CSS from "csstype";


type StateProps =
    GlobalState & OrderState & WithTranslation &
    typeof GlobalActions.messageCreator & typeof GlobalActions.configurationCreator &
    RouteComponentProps<{}>;

 class WelcomeComponent extends React.PureComponent<StateProps> {

    collect = async () => {
        if (this.props.isPreview)
            return;

        const startCollectResult = await ApiService.startCollectProcess();
        ApiService.isProcessStarted = true;

        this.props.setAgeControlType(startCollectResult.ageControlType);

        if (startCollectResult.attendantNotified) {
            this.props.setIsNotificationSented(startCollectResult.attendantNotified);
        }
        else if (startCollectResult.errorCode === 1) {
            history.push('/order-not-available');
            return;
        }

        //TODO check this place. Temp fix
//        else {
//            history.push('/error');
//            return;
//        }

        if (this.props.authConfigurations.terminalIdScanAuthentification ||
            this.props.authConfigurations.useDeviceLocationAuthentification)
            history.push('/authentificate');
        else
            history.push('/collect');
    }

    render(){
        const { t } = this.props;

        let fontStyle: CSS.Properties = {
            color: this.props.configurations.fontColor
        }

        const buttonStyles = {
            backgroundColor:this.props.configurations.buttonBackgroundColor,
            color: this.props.configurations.buttonFontColor
        }

        let order : IOrder = {
            orderNumber: null,
            address: null,
            status: null,
            returnAfterTime:null,
            hasAdditionalItems: false,
            hasPendingPackages: false
        };

        if(this.props.order){
            order  = {
                orderNumber: this.props.order.orderNumber,
                address: this.props.order.address,
                status: this.props.order.status,
                returnAfterTime: this.props.order.returnAfterTime,
                hasAdditionalItems: this.props.order.hasAdditionalItems,
                hasPendingPackages: this.props.order.hasPendingPackages
            };
        }

        return (
            <div style={fontStyle} className="text-center">
                <h1 dangerouslySetInnerHTML={{__html:t("WebLinkWelcomePage_WelcomeText")}}/>
                <OrderInfoLabelComponent addressText="WebLinkWelcomePage_TerminalLocation" address={order.address} orderInformationText="WebLinkWelcomePage_OrderInformation" orderNumber={order.orderNumber}/>
                <div style={{ fontSize: '14pt' }} className="my-4 button-info" dangerouslySetInnerHTML={{__html:t("WebLinkWelcomePage_TextAboveCollectButton")}} />
                <button onClick={this.collect} className="main-button" style={buttonStyles}>{t('WebLinkWelcomePage_CollectButtonText')}</button>
                <MessageLabelComponent message={this.props.message} messageType={this.props.messageType} textToReplace={this.props.authConfigurations.distance}/>
            </div>
        )
    }
}

const welcomeComponent = connect(
    (state: any) => Object.assign({}, state.globalReducer, state.orderReducer),
    Object.assign({}, GlobalActions.messageCreator
        ,GlobalActions.configurationCreator)
)(WelcomeComponent);

export default withTranslation()(welcomeComponent);
