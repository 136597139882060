import React from 'react';
import { Route, Redirect } from "react-router-dom";



const AuthentificateGuard = ({component:Component, order, authConfigurations,isPreview, ...rest }) => (
    <Route {...rest} render={ (props) => {
        const component = <Component {...rest} {...props} />;
       if(isPreview)
            return component; 
        else if(!order)
            return null; 
        else if(!authConfigurations || 
            (!authConfigurations.useDeviceLocationAuthentification 
            && !authConfigurations.terminalIdScanAuthentification ) )
            return <Redirect to='/collect' />;
        else        
            return component;       
        }
        
    } />
)

export default AuthentificateGuard;