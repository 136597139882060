import React from 'react'
import {withTranslation, WithTranslation} from 'react-i18next';
import {GlobalState} from '../../models/global-state';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import * as GlobalActions from '../../redux/global/global.action';
import OrderState from '../../models/order.state';
import {history} from '../../helpers/history.helper'
// import Quagga from 'quagga';
import {IOrder} from '../../models/order';
import OrderInfoLabelComponent from '../order-info-label/order-info-label.component';
import ApiService from '../../services/api.service';
import CSS from "csstype";
import { BrowserCodeReader, BrowserQRCodeReader } from '@zxing/browser';
import MessageLabelComponent from '../message-label/message-label.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as solidIcon from '@fortawesome/free-solid-svg-icons'

type State = {
    readQrCode: boolean,
    cameras: MediaDeviceInfo[],
    cameraId: string | undefined,
    text: string | undefined,
    codeReader: BrowserQRCodeReader,
    inAction: boolean
}

type StateProps =
    GlobalState & OrderState & WithTranslation &
    typeof GlobalActions.messageCreator & typeof GlobalActions.configurationCreator &
    RouteComponentProps<{}>;

class ScanQrCode extends React.PureComponent<StateProps, State> {
    constructor(props:any){
        super(props);
    this.state = { readQrCode: false, cameras: [], cameraId: undefined, text: undefined, codeReader: new BrowserQRCodeReader(undefined,  { delayBetweenScanSuccess: 2000, delayBetweenScanAttempts: 1000 }), inAction: false };
        this.getCamerasAndStart = this.getCamerasAndStart.bind(this);
    }
    componentDidMount() {
        //let videoInputDevices: any[] = [];
        this.requestCamerasPermissions()
        .then( () => {
            this.getCamerasAndStart();
        }).catch(e => {
            console.log(e);
            this.handleError(e);
        });
    }

    getCamerasAndStart = () => {
        BrowserCodeReader.listVideoInputDevices().then(res => {
            if(res && res.length > 0)
            {
                let videoCameras = res.reverse();
                let defaultCameraId = videoCameras[0].deviceId;
                //let lastRearVideoCamera = videoCameras.find( i => { return i.label.indexOf('back') > 0});
                let rearVideoCamera = videoCameras.find( i => { return /back|trás|rear|traseira|environment|ambiente/gi.test(i.label) });

                if(rearVideoCamera)
                    defaultCameraId = rearVideoCamera.deviceId;

                //console.log(videoCameras);
                this.setState({cameras: videoCameras});
                this.handleCameraChange(defaultCameraId);
            }else{
                this.props.showErrorMessage("WebLinkAuthentificationScreen_QrScannerNotSupportingErrorText");
                console.log('No devices');
            }
        }
        ).catch(e => {
            this.handleError(e)
            console.log(e);
        });
    }

    requestCamerasPermissions = () => {
        return new Promise((resolve, reject) => {
            if (navigator.mediaDevices
                && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia(
                    { audio: false, video: true })
                    .then(stream => {
                        // hacky approach to close any active stream if they are and ask permissions
                        const tracks = stream.getVideoTracks();
                        for (var i = 0; i < tracks.length; i++) {
                            const track = tracks[i];
                            track.enabled = false;
                            stream.removeTrack(track);
                            track.stop();
                            //stream.removeTrack(track);
                        }
                        resolve('access granted');
                    })
                    .catch(e => {
                        //reject(`${e.name} : ${e.message}`);
                        reject(e.message);
                    })
            } else {
                reject("unable to query supported devices.");
            }
        });
    }

    handleCameraChange = (cameraId: any) => {
        this.props.clearMessage();
        //console.log("setting cameraId")
        if(this.state.cameraId){
            let videoElm = BrowserCodeReader.prepareVideoElement('video');
            this.stopStreamedVideo(videoElm);
            this.setState({readQrCode: false, inAction: true});
            //wait all previous readings to finish before starting new
            setTimeout(() => {
                this.setState({cameraId: cameraId, readQrCode: true, inAction: false});
                this.decodeContinuously(cameraId);
            }, 1500);
            /*this.requestCamerasPermissions().then(res => {
                this.setState({cameraId: cameraId, readQrCode: true, inAction: false});
                this.decodeContinuously(cameraId);
            }).catch( e => {
                console.log(e);
                this.setState({cameraId: cameraId, readQrCode: true, inAction: false});
                this.decodeContinuously(cameraId);
            });*/
        }else{
            this.setState({cameraId: cameraId, readQrCode: true});
            this.decodeContinuously(cameraId);
        }
    }

    decodeContinuously = (cameraId: any) => {
        let videoElm = BrowserCodeReader.prepareVideoElement('video');
        this.state.codeReader.decodeFromVideoDevice(cameraId, videoElm, (result, error, controls)=> {
            if(!this.state || !this.state.readQrCode){
                //console.log('cleaning up');
                this.stopStreamedVideo(videoElm);
                controls.stop();
                //setTimeout(() => { try{controls.stop()} catch{}}, 2000);
                return;
            }
            //console.log('reading' + this.state.readQrCode);
            if(result){
                this.stopStreamedVideo(videoElm);
                controls.stop(); //if camera was turned on focused on qr it may cause "Cannot read property 'getVideoTracks' of undefined" but it seems nothing wrong - gues qrcode was read before some tracks was not initialyzed
                //setTimeout(() => { try{controls.stop()} catch{}}, 2000);
                //console.log(result);
                this.onCodeDetected(result.getText());
            }else if(error && error.getKind() !== "NotFoundException" &&
                     error.getKind() !== "FormatException" &&
                     error.getKind() !== "ChecksumException"){
                console.log(error);
                this.stopStreamedVideo(videoElm);
                controls.stop();
                //setTimeout(() => { try{controls.stop()} catch{}}, 2000);
                this.handleError(error.message);
            }
        }).then(controls => {
            //console.log('new scan initiated');
        }).catch((err: any) => {
            console.log('decodeFromVideoDevice:' + err);
            if(this.state && this.state.readQrCode){
                this.setState({readQrCode: false});
                this.handleError(err);
            }
        });
    }

    getCameraOptions = () => {
        return (this.state.cameras.length > 0 && <select value={this.state.cameraId} onChange={(e) => this.handleCameraChange(e.target.value)} className="form-control">
        {this.state.cameras.map((cam: any) => {
                return <option key={cam.deviceId} value={cam.deviceId}>
                  {cam.label}
                </option>
        })}
        </select>);
    }

    //after scan lob does not stop video and does not return control how to stop it so for now I use decodeContinuously since it returns control
    /*decodeOnce = (cameraId: any) => {
        this.props.clearMessage();
        let videoElm = BrowserCodeReader.prepareVideoElement('video');
        this.state.codeReader.decodeOnceFromVideoDevice(cameraId, videoElm).then((result: any) => {
          //console.log(result.text);
          //BrowserCodeReader.cleanVideoSource()
          this.onCodeDetected(result.text);
         // BrowserCodeReader.cleanVideoSource(videoElm);
          this.stopStreamedVideo(videoElm);
          BrowserCodeReader.cleanVideoSource(videoElm);
          //codeReader.reset();
        }).catch((err: any) => {
          console.error(err);
          //this.handleError(err);
        });
    }*/

    //workaround for chrome on android 11, remove track before stopping it
    stopStreamedVideo = (videoElem: any) => {
        const stream = videoElem.srcObject;
        //console.log(videoElem.srcObject);
        if(stream){
            //console.log('stopping tracks');
            const tracks = stream.getTracks();
            //console.log(tracks);
            //BrowserCodeReader.cleanVideoSource(videoElem);
            tracks.forEach((track: any) => {
                stream.removeTrack(track); //remove track before stopping as workaround for chrome freeze problem on android 11
                track.stop();
                //stream.removeTrack(track);
            });
            //videoElem.srcObject = null;
        }
    }

    onCodeDetected = (code: string | undefined, manual: boolean = false) => {
        if (!code)
            return
        else if (!this.state.readQrCode && !manual)
            return;
        else if (this.props.isPreview)
            return;
        else if (!this.props.linkCode) {
            return;
        }
        this.setState({...this.state, readQrCode: false});
        ApiService.authByCode(code).then(res => {
            if (res.status === 1) {
                this.props.clearMessage();
                this.props.authentificateLocation(true);
                history.push('/collect');
                return;
            }
            this.props.showErrorMessage('WebLinkAuthentificationScreen_WrongTerminalIdText');
            history.push('/welcome');
        }).catch(err => {
            this.props.showErrorMessage('WebLinkAuthentificationScreen_WrongTerminalIdText');
            history.push('/welcome');
        });
    }

    handleError = (err: any) => {
        const {t} = this.props;
        let message = t('WebLinkTerminalQrCodeScanScreen_QrScannerErrorText');
        message = message.replace(/\[text\]/gi, err);
        //console.log(' (' + message + ')');
        this.props.showErrorMessage(message);
    }

    handleTextAreaChange = (event:any)=>{
        this.setState({
            text: event.target.value
        });
    };

    render() {
        const {t} = this.props;

        let buttonStyles = {
            backgroundColor:this.props.configurations.buttonBackgroundColor,
            color: this.props.configurations.buttonFontColor
        }
        let order: IOrder = {
            orderNumber: null,
            address: null,
            status: null,
            returnAfterTime: null,
            hasAdditionalItems: false,
            hasPendingPackages: false
        };

        const videoStyle:CSS.Properties = {
            border: '1px solid gray',
            //maxHeight: '50vh',
            objectFit: 'cover',
            width: '100%',
            height: "100%",
            position: 'absolute',
            top: 0,
            left: 0
        }

        if (this.props.order) {
            order = {
                orderNumber: this.props.order.orderNumber,
                address: this.props.order.address,
                status: this.props.order.status,
                returnAfterTime: this.props.order.returnAfterTime,
                hasAdditionalItems: this.props.order.hasAdditionalItems,
                hasPendingPackages: this.props.order.hasPendingPackages
            };
        }

        let fontStyle: CSS.Properties = {
            color: this.props.configurations.fontColor
        }

        let loadingIndicatorStyle: CSS.Properties = {
            backgroundColor: 'white',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            position: 'absolute',
            opacity: 0.5,
            display: 'none',
            textAlign: 'center',
            zIndex: 10
        };

        if(this.state.inAction){
            loadingIndicatorStyle.display = 'block';
        }

        let scanFrameStyle: CSS.Properties = {
            top: 0,
            left: 0,
            zIndex: 1,
            boxSizing: 'border-box',
            border: '70px solid rgba(0, 0, 0, 0.3)',
            boxShadow: 'rgba(255, 255, 255, 0.5) 0px 0px 0px 10px inset',
            position: 'absolute',
            width: '100%',
            height: '100%'
        };

        return <div style={fontStyle} className="text-center">
            <h1 dangerouslySetInnerHTML={{__html: t("WebLinkWelcomePage_WelcomeText")}}/>
            <OrderInfoLabelComponent addressText="WebLinkWelcomePage_TerminalLocation" address={order.address}
                                     orderInformationText="WebLinkWelcomePage_OrderInformation"
                                     orderNumber={order.orderNumber}/>
            <div className="button-info"
                 dangerouslySetInnerHTML={{__html: t("WebLinkTerminalQrCodeScanScreen_ExplanationButtonText")}}/>
            {/* <div id="barcodeScanner"></div> */}
            <div className="col-md-auto">
                <div className="form-group">
                    {this.getCameraOptions()}
                </div>
            </div>
            <MessageLabelComponent message={this.props.message} messageType={this.props.messageType}/>
            <div>
                <section style={{
                    position: 'relative',
                    width: '100%',
                    paddingTop: '100%'
                }}>
                    <div style={scanFrameStyle} />
                    <video id="video" style={videoStyle}></video>
                </section>
                <div style={loadingIndicatorStyle}><FontAwesomeIcon style={{height: '100%'}} className="fas fa-spinner" icon={solidIcon.faSpinner} spin size="7x"/></div>
            </div>
            <div className="button-info"/>
            <div className="button-info"
                        dangerouslySetInnerHTML={{__html: t("WebLinkTerminalQrCodeScanScreen_EnterQrCodeManuallyText")}}/>
            <div className="col-md-auto">
                <div className="form-group">
                    <input className="form-control" onChange={this.handleTextAreaChange}></input>
                </div>
            </div>
            <button className="main-button" style={buttonStyles} onClick={() => this.onCodeDetected(this.state.text, true)}>{t('WebLinkTerminalQrCodeScanScreen_SubmitQrCodeManuallyButton')}</button>
        </div>
    }

    componentWillUnmount() {
        this.setState({readQrCode : false});
    }
}

const scanQrCodeRedux = connect(
    (state: any) => Object.assign({}, state.globalReducer, state.orderReducer),
    Object.assign({}, GlobalActions.messageCreator, GlobalActions.configurationCreator)
)(ScanQrCode);

export default withTranslation()(scanQrCodeRedux);
