import * as React from 'react';
import { Container } from 'reactstrap';
import HeaderComponent from './header.component';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <HeaderComponent />
            <Container className="body-container ">
                    {props.children}
            </Container>
    </React.Fragment>
);
