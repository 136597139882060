import React from 'react';
import {Route, Redirect} from "react-router-dom";


const WelcomeGuard = ({component: Component, order, isPreview, ...rest}) => (
    <Route {...rest} render={(props) => {
        const component = <Component {...rest} {...props} />;

        if (isPreview)
            return component;
        else if (!order)
            return null;
        else if (order.returnAfterTime && new Date(order.returnAfterTime).setDate(new Date(order.returnAfterTime).getDate() + 7) < new Date())
            return <Redirect to='/order-not-available'/>;
        else if ((order.returnAfterTime && new Date(order.returnAfterTime) < new Date()))
            return <Redirect to='/order-expired'/>;
        else
            return component;
    }

    }/>
)

export default WelcomeGuard;