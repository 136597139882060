import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import { GlobalState } from '../../models/global-state';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import  * as GlobalActions from '../../redux/global/global.action';
import OrderState from '../../models/order.state';
import { IOrder } from '../../models/order';
import CSS from 'csstype'
import OrderInfoLabelComponent from '../order-info-label/order-info-label.component';

type StateProps =
    GlobalState & OrderState & WithTranslation &
    typeof GlobalActions.messageCreator & typeof GlobalActions.configurationCreator &
    RouteComponentProps<{}>;

 class OrderExpiredComponent extends React.PureComponent<StateProps>{

    render(){
        const { t } = this.props;
        
        let order : IOrder = {
            orderNumber: null,
            address: null,
            status: null,
            returnAfterTime:null,
            hasAdditionalItems: false,
            hasPendingPackages: false
        };

        if(this.props.order){
            order  = {
                orderNumber: this.props.order.orderNumber,
                address: this.props.order.address,
                status: this.props.order.status,
                returnAfterTime: this.props.order.returnAfterTime,
                hasAdditionalItems: this.props.order.hasAdditionalItems,
                hasPendingPackages: this.props.order.hasPendingPackages
            };
        }

        let fontStyle: CSS.Properties = {
            color: this.props.configurations.fontColor
        }
        
        return (
            <div style={fontStyle} className="text-center">
                <h1 dangerouslySetInnerHTML={{__html:t("WebLinkWelcomePage_WelcomeText")}} />
                <OrderInfoLabelComponent addressText="WebLinkWelcomePage_TerminalLocation" address={order.address} orderInformationText="WebLinkWelcomePage_OrderInformation" orderNumber={order.orderNumber}/>
                <div className="order-message" dangerouslySetInnerHTML={{__html:t("WebLinkWelcomePage_OrderExpiredText")}} />
            </div>
        )
    }
}

const orderExpiredComponent = connect(
    (state: any) => Object.assign({}, state.globalReducer, state.orderReducer),
    Object.assign({}, GlobalActions.messageCreator
        ,GlobalActions.configurationCreator)
)(OrderExpiredComponent);
 
 export default withTranslation()(orderExpiredComponent);
