import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import { GlobalState } from '../../models/global-state';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import  * as GlobalActions from '../../redux/global/global.action';
import OrderState from '../../models/order.state';
import { history } from '../../helpers/history.helper'
import { IOrder } from '../../models/order';
import OrderInfoLabelComponent from '../order-info-label/order-info-label.component';
import ApiService from '../../services/api.service'
import MessageLabelComponent from '../message-label/message-label.component';
import CSS from 'csstype'



type StateProps =
    GlobalState & OrderState & WithTranslation &
    typeof GlobalActions.messageCreator & typeof GlobalActions.configurationCreator &
    RouteComponentProps<{}>;

 class AuthentificateComponent extends React.PureComponent<StateProps>{
     
    componentDidMount(){
        this.props.clearMessage();
    }

    useLocation = () => {
        if(this.props.isPreview)
            return;
        else if(!this.props.linkCode)
            return;
        else if ("geolocation" in navigator) {
            let props = {
                linkCode : this.props.linkCode,
                authentificateLocation : this.props.authentificateLocation,
                clearMessage : this.props.clearMessage,
                showErrorMessage: this.props.showErrorMessage
            };

            navigator.geolocation.getCurrentPosition(function(position) {
                ApiService.authByDistance(position.coords.latitude,position.coords.longitude).then(res=>{
                    if(res.status === 1){
                        props.clearMessage();
                        props.authentificateLocation(true);
                        history.push('/collect');
                        return;
                    }

                    props.showErrorMessage('WebLinkAuthentificationScreen_FailedLocationText');
                    history.push('/welcome');
                }).catch( err => {
                    props.showErrorMessage('WebLinkAuthentificationScreen_FailedLocationText');
                    history.push('/welcome');
                });
                
            },() => {
                this.props.showErrorMessage('WebLinkAuthentificationScreen_FailedLocationText');
                history.push('/welcome');},{
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0
                });
        } else {
            this.props.showErrorMessage('WebLinkAuthentificationScreen_FailedLocationText');
            history.push('/welcome');
        }

        
    } 

    scanQrCode = () => {
        if(this.props.isPreview)
            return;

        this.props.clearMessage();
        history.push('/scan-qr-code'); 
        return;
    } 

    render(){
        const { t } = this.props;

        const buttonStyles = {
            backgroundColor:this.props.configurations.buttonBackgroundColor,
            color: this.props.configurations.buttonFontColor
        }

        let useLocationButton;
        if(this.props.authConfigurations.useDeviceLocationAuthentification){
            useLocationButton = <button onClick={this.useLocation} className="main-button" style={buttonStyles}>{t('WebLinkAuthentificationScreen_LocationAuthentificationButton')}</button> 
        }
        let scanQrCodeButton;
        if(this.props.authConfigurations.terminalIdScanAuthentification){
            scanQrCodeButton = <button onClick={this.scanQrCode} className="main-button" style={buttonStyles}>{t('WebLinkAuthentificationScreen_TerminalIdScanButton')}</button>
        }

        let order : IOrder = {
            orderNumber: null,
            address: null,
            status: null,
            returnAfterTime: null,
            hasAdditionalItems: false,
            hasPendingPackages: false
        };

        if(this.props.order){
            order  = {
                orderNumber: this.props.order.orderNumber,
                address: this.props.order.address,
                status: this.props.order.status,
                returnAfterTime: this.props.order.returnAfterTime,
                hasAdditionalItems: this.props.order.hasAdditionalItems,
                hasPendingPackages: this.props.order.hasPendingPackages
            };
        }

        let fontStyle: CSS.Properties = {
            color: this.props.configurations.fontColor
        }

        return <div style={fontStyle} className="text-center">
                    <h1 dangerouslySetInnerHTML={{__html:t("WebLinkWelcomePage_WelcomeText")}}/>
                    <OrderInfoLabelComponent addressText="WebLinkWelcomePage_TerminalLocation" address={order.address} orderInformationText="WebLinkWelcomePage_OrderInformation" orderNumber={order.orderNumber}/>
                    <div style={{ fontSize: '14pt' }} className="my-4 button-info" dangerouslySetInnerHTML={{__html:t("WebLinkAuthentificationScreen_ExplanationButtonText")}} />
                    {useLocationButton}
                    {scanQrCodeButton}
                    <MessageLabelComponent message={this.props.message} messageType={this.props.messageType} textToReplace={this.props.authConfigurations.distance}/>
        </div>
    }

    componentWillUnmount(){
      
    }
}

const authentificateComponent = connect(
    (state: any) => Object.assign({}, state.globalReducer, state.orderReducer),
    Object.assign({}, GlobalActions.messageCreator,GlobalActions.configurationCreator)
)(AuthentificateComponent);

export default withTranslation()(authentificateComponent);


