import * as ActionType from "./global.type";
import { GlobalState } from "../../models/global-state";
import { MessageTypeEnum } from "../../enums/message-type-enum";
import { AnyAction } from "redux";



const initialState: GlobalState = {
    linkCode:null,
    configurations:{ageControlledBoxColor:'',
        ageControlledBoxFontColor:'',
        ageControlledLockButtonColor:'',
        backgroundColor:'',
        buttonFontColor:'',
        fontColor:'',
        boxFrameColor:'',
        buttonBackgroundColor:'',
        clockFrameColor:'',
        defaultLanguage:'',
        headerBackgroundColor:'',
        lockBoxFontColor:'',
        lockButtonColor:'',
        lockButtonFrameColor:'',
        logoPicture:''	,
        notificationTextColor:'',
        secondaryLanguage:'',
        unlockButtonFrameColor:'',
        unlockBoxFontColor:'',
        unlockButtonColor:'',
        collectedBoxColor: '',
        collectedBoxFontColor: '',
        uncollectedBoxColor: '',
        uncollectedBoxFontColor:'',
        sessionTimeout: 0,
        enableDefaultLanguage: false,
        defaultLanguageName: '',
        defaultLanguageIcon: ''
    },
    translations: {},
    message: '',
    messageType : MessageTypeEnum.default,
    loading: false,
    fullLoading: true,
    authConfigurations:{
        useDeviceLocationAuthentification: false,
        terminalIdScanAuthentification: false,
        distance:0
    },
    isLocationValid: false,
    isQrCodeValid: false,
    tenantId: null,
    isPreview: null,
    isDmIntegrationEnabled: false,
    isNotificationSented: false,
    ageControlType: 0
};



const globalReducer = (state = initialState, action: AnyAction):GlobalState => {
    switch (action.type) {
        case ActionType.SET_LINK_CODE:{
            return {
                ...state,
                linkCode: action.data
            };
        }
        case ActionType.CLEAR_LINK_CODE:{
            return {
                ...state,
                linkCode: null
            };
        }
        case ActionType.SET_CONFIGURATIONS:{
            return {
                ...state,
                configurations: action.data
            };
        }
        case ActionType.SET_AUTH_CONFIGURATIONS:{
            return {
                ...state,
                authConfigurations: action.data
            };
        }
        case ActionType.SET_TRANSLATIONS:{
            return {
                ...state,
                translations: action.datas
            };
        }
        case ActionType.SET_PREVIEW_TENANT_ID:{
            return {
                ...state,
                tenantId: action.data,
                isPreview: !!action.data
            };
        }
        // case LOAD_ORDER_SUCCESS:{
        //     return Object.assign({}, state, {
        //         ...state,
        //         order : action.data
        //     });
        // }
        case ActionType.MANAGE_LOADING: {
            return {
                ...state,
                loading: action.data
            };
        }
        case ActionType.SHOW_FULL_LOADER: {
            return {
                ...state,
                fullLoading: true
            };
        }
        case ActionType.HIDE_FULL_LOADER: {
            return {
                ...state,
                fullLoading: false
            };
        }
        case ActionType.MESSAGE_SUCCESS:
        case ActionType.MESSAGE_ERROR:
        case ActionType.MESSAGE_WARNING: {
            return {
                ...state,
                message: action.message,
                messageType: action.messageType,
                loading: false
            };
        }
        case ActionType.MESSAGE_CLEAR: {
            return {
                ...state,
                message: '',
                messageType: MessageTypeEnum.default,
                loading: false
            };
        }
        case ActionType.AUTHENTIFICATE_QR_CODE: {
            return {
                ...state,
                isQrCodeValid: true
            };
        }
        case ActionType.AUTHENTIFICATE_LOCATION: {
            return {
                ...state,
                isLocationValid: true
            };
        }
        case ActionType.SET_IS_DM_INTEGRATION_ENABLED: {
            return {
                ...state,
                isDmIntegrationEnabled: action.data
            }
        }
        case ActionType.SET_IS_NOTIFICATION_SENDED:
        {
            return {
                ...state,
                isNotificationSented: action.data
            }
        }
        case ActionType.SET_AGE_CONTROL_TYPE: {
            return {
                ...state,
                ageControlType: action.data
            }
        }
        // case ActionType.SET_IS_PENDING_ORDER_NOTIFICATION_SENT: {
        //     return {
        //         ...state,
        //         isPendingOrderNotificationSent: action.data
        //     }
        // }
        default:
            return state;
    }
};

export default globalReducer;
