import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import { MessageTypeEnum } from '../../enums/message-type-enum';

interface props{
    messageType:MessageTypeEnum;
    message:string | null;
}

type StateProps =
    WithTranslation & props & any;

 class MessageLabelComponent extends React.PureComponent<StateProps>{
    componentDidMount(){

    }

    render(){
        const { t,message } = this.props;
        let result = null;
        if(message){
            let text = t(message);
            if(!text)
                return;

            if(this.props.textToReplace && message === 'WebLinkAuthentificationScreen_FailedLocationText'){
                text = text.replace(/\[number\]/gi, this.props.textToReplace)
            }

            result = <div className={MessageTypeEnum[this.props.messageType] + '-message'} dangerouslySetInnerHTML={{__html:text}} />;
        }

        return result
    }

    componentWillUnmount(){

    }
}

export default withTranslation()(MessageLabelComponent);
