import React from 'react';
import { connect } from 'react-redux';
import { GlobalState } from '../../models/global-state';
import  * as GlobalActions from '../../redux/global/global.action';
import { MessageTypeEnum } from '../../enums/message-type-enum';
import MessageLabelComponent from '../message-label/message-label.component';

type StateProps = typeof GlobalActions.messageCreator & typeof GlobalActions.managePreloader & GlobalState;

class ErrorComponent extends React.Component<StateProps> {
  componentDidMount() {
    if(!this.props.message || this.props.messageType !== MessageTypeEnum.error)
        this.props.showErrorMessage('Oops! Something went wrong. Please reopen the link.');

    this.props.hideFullLoader();
  }

  render() {
    return (
      <div className="text-center">
        <MessageLabelComponent message={this.props.message} messageType={this.props.messageType}/>
      </div>);
  }
}

export default connect(
  (state: any) => Object.assign({}, state.globalReducer),
  Object.assign({}, GlobalActions.messageCreator, GlobalActions.managePreloader),
)(ErrorComponent);
