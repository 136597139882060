import React from 'react';
import { Route, Redirect } from "react-router-dom";



const ScanQrCodeGuard = ({component:Component, order, authConfigurations,isPreview, ...rest }) => (

    <Route {...rest} render={ (props) => {
        const component = <Component {...rest} {...props} />;
       if(isPreview)
            return component; 
        else if(!order)
            return null; 
        else if(!authConfigurations || 
                !authConfigurations.terminalIdScanAuthentification ) 
                return <Redirect to='/authentificate' />;
        else
            return component        
        }
        
    } />
)

export default ScanQrCodeGuard;