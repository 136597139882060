import * as type from "./order.types";
import OrderState from "../../models/order.state";
import { AnyAction } from "redux";



const initialState: OrderState = {
    order: null,
    boxes: null
};



const orderReducer = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case type.SET_ORDER:{
            return {
                ...state,
                order: action.data
            };
        }
        case type.SET_BOXES:{
            return {
                ...state,
                boxes: action.data
            };
        }

        default:
            return state;
    }
};

export default orderReducer;
