import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next';
import Box from '../../models/box';
import { BoxActions } from './box.action';
import { Configuration } from '../../models/configuration';
import CSS from 'csstype';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as solidIcon from '@fortawesome/free-solid-svg-icons'

type StateProps =
    WithTranslation & Box & BoxActions & {configurations:Configuration};

 class BoxComponent extends React.PureComponent<StateProps>{
    componentDidMount(){

    }

    render(){
        const { t } = this.props;
        let boxStyle:CSS.Properties = {};
        let buttonStyle:CSS.Properties = {};
        let buttonLabel;
        let buttonIconContainerStyle: CSS.Properties = {};
        let buttonIconStyle: CSS.Properties = {};
        let boxLabelStyle: CSS.Properties = {};
        let lockAction = (boxNo:string) => {
            return;
        }

        boxStyle.border = `2px solid ${this.props.configurations.boxFrameColor}`;
        buttonStyle.border = `2px solid ${this.props.configurations.boxFrameColor}`;
        buttonIconContainerStyle.border = `2px solid ${this.props.configurations.boxFrameColor}`;

        if(this.props.isCollected){
            boxLabelStyle.color = this.props.configurations.collectedBoxFontColor;
            boxStyle.backgroundColor = this.props.configurations.collectedBoxColor;
            boxStyle.color = this.props.configurations.collectedBoxFontColor;
        }else{
            boxLabelStyle.color = this.props.configurations.uncollectedBoxFontColor;
            boxStyle.backgroundColor = this.props.configurations.uncollectedBoxColor;
            boxStyle.color = this.props.configurations.uncollectedBoxFontColor;
        }

        if(this.props.isAgeControlled){
            boxLabelStyle.color = this.props.configurations.ageControlledBoxFontColor;

            boxStyle.backgroundColor = this.props.configurations.ageControlledBoxColor;
            boxStyle.color = this.props.configurations.ageControlledBoxFontColor;

            buttonStyle.backgroundColor = this.props.configurations.ageControlledLockButtonColor;
            buttonStyle.color = this.props.configurations.fontColor;
            buttonStyle.paddingLeft = '44px';
            buttonStyle.textAlign = 'left';
            buttonStyle.borderColor = this.props.configurations.unlockButtonFrameColor;

            buttonIconStyle.color = this.props.configurations.unlockButtonFrameColor;

            buttonIconContainerStyle.borderColor = this.props.configurations.unlockButtonFrameColor;
            buttonIconContainerStyle.left = '-2px';

            let lockButtonText:string = t('WebLinkCollectScreen_LockButtonText');
            let lockButtonTitle:string = '';
            if(lockButtonText.length > 6 )
            {
                lockButtonTitle = lockButtonText;
                lockButtonText = '';
            }
            buttonLabel = <div style={buttonStyle} className="box-button" title={lockButtonTitle}><i className="lock-icon-container" style={buttonIconContainerStyle}><FontAwesomeIcon className="box-lock-icon" icon={solidIcon.faLock} style={buttonIconStyle}/></i>{lockButtonText}</div>
        }else if(this.props.isLocked){
            buttonStyle.backgroundColor = this.props.configurations.unlockButtonColor;
            buttonStyle.color = this.props.configurations.fontColor;
            buttonStyle.paddingLeft = '44px';
            buttonStyle.textAlign = 'left';
            buttonStyle.borderColor = this.props.configurations.unlockButtonFrameColor;

            buttonIconStyle.color = this.props.configurations.unlockButtonFrameColor;

            buttonIconContainerStyle.left = '-2px';
            buttonIconContainerStyle.borderColor = this.props.configurations.unlockButtonFrameColor;

            let unlockButtonText:string = t('WebLinkCollectScreen_UnlockButtonText');
            let lockButtonTitle:string = '';
            if(unlockButtonText.length > 6 )
            {
                lockButtonTitle = unlockButtonText;
                unlockButtonText = '';
            }

            buttonLabel =  <div style={buttonStyle} className="box-button" title={lockButtonTitle}><i className="lock-icon-container" style={buttonIconContainerStyle}><FontAwesomeIcon className="box-lock-icon" icon={solidIcon.faLock} style={buttonIconStyle}/></i>{unlockButtonText}</div>

            if(!this.props.inAction){
                lockAction = (boxNo:string) => {
                    return this.props.unlock(boxNo);
                }
            }
        }else{
            let clockUnlockDivStyle:CSS.Properties = {
                backgroundColor: '#FFFFFF',
                display:'inline-block',
                width: '10px',
                height:'5px',
                position:'absolute',
                left: '17px',
                top:'12px'
            }

            buttonStyle.backgroundColor = this.props.configurations.lockButtonColor;
            buttonStyle.color = this.props.configurations.fontColor;
            buttonStyle.paddingRight = '44px';
            buttonStyle.textAlign = 'right';
            buttonStyle.borderColor = this.props.configurations.lockButtonFrameColor;

            buttonIconStyle.color = this.props.configurations.lockButtonFrameColor;

            buttonIconContainerStyle.right = '-2px';
            buttonIconContainerStyle.left = 'unset';
            buttonIconContainerStyle.borderColor = this.props.configurations.lockButtonFrameColor;

            let lockButtonText:string = t('WebLinkCollectScreen_LockButtonText');
            let lockButtonTitle:string = '';
            if(lockButtonText.length > 6 )
            {
                lockButtonTitle = lockButtonText;
                lockButtonText = '';
            }

            buttonLabel =  <div style={buttonStyle} className="box-button" title={lockButtonTitle}>{lockButtonText}<i className="lock-icon-container" style={buttonIconContainerStyle}><FontAwesomeIcon className="box-lock-icon" icon={solidIcon.faLock} style={buttonIconStyle}/><div style={clockUnlockDivStyle}></div></i></div>

            if(!this.props.inAction){
                lockAction = (boxNo:string) => {
                    return this.props.lock(boxNo);
                }
            }
        }

        let loadingIndicatorStyle: CSS.Properties = {};
        if(this.props.inAction){
            loadingIndicatorStyle.display = 'block';
        }

        return <div className="box-container" onClick={() => lockAction(this.props.boxNo)}>
                    <div style={boxStyle} className="box">
                        <div style={boxLabelStyle} className="box-no">{this.props.boxNo}</div>
                         {buttonLabel}
                         <div style={loadingIndicatorStyle} className="box-loading-indicator"><FontAwesomeIcon className="fas fa-spinner" icon={solidIcon.faSpinner} spin size="7x"/></div>
                    </div>
                </div>
    }

    componentWillUnmount(){

    }
}


export default withTranslation()(BoxComponent);
