import React from 'react';
import { Route, Redirect } from "react-router-dom";



const CollectGuard = ({component:Component,order, isQrCodeValid, isLocationValid, authConfigurations,isPreview, ...rest }) => (
    <Route {...rest} render={ (props) => {    
        const component = <Component {...rest} {...props} />;
    
        if(isPreview)
            return component; 
        else if(!order)
            return null; 
        else if(authConfigurations && 
                (authConfigurations.useDeviceLocationAuthentification && !isQrCodeValid)
                && (authConfigurations.terminalIdScanAuthentification && !isLocationValid))
                    return <Redirect to='/authentificate' />;
        else
            return component;      
    }} />
)

export default CollectGuard;