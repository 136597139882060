import * as React from 'react';
import { NavbarBrand} from 'reactstrap';
import { Link } from 'react-router-dom';
import './header.css';
import { connect } from 'react-redux';
import i18n from 'i18next';
import  * as GlobalActions from '../redux/global/global.action';
import CSS from 'csstype';


class HeaderComponent extends React.PureComponent<any> {
    public state = {
        isOpen: false
    };

    public render() {
        let headerStyle: CSS.Properties = {};
        
        headerStyle.backgroundColor = this.props.configurations.headerBackgroundColor;
        let isDefaultLanguageEnabled = this.props.configurations.enabledDefaultLanguage;
        let defaultLanguageKey = this.props.configurations.defaultLanguageName;
        let defaultLanguageIcon = this.props.configurations.defaultLanguageIcon;
        let flagUrl = `https://lockercenprodstoaccus.blob.core.windows.net/countryflags/${defaultLanguageIcon}.png`;

        let langKeys: any[] = [];
        if (this.props.configurations.defaultLanguage)
            langKeys.push({ 'languageKey': this.props.configurations.defaultLanguage, 'isDefault': isDefaultLanguageEnabled ? "dflt" == this.props.configurations.defaultLanguage : false });

        if (this.props.configurations.secondaryLanguage)
            langKeys.push({ 'languageKey': this.props.configurations.secondaryLanguage, 'isDefault': isDefaultLanguageEnabled ? "dflt" == this.props.configurations.secondaryLanguage : false });

        let logo;
        if(this.props.configurations.logoPicture)
            logo = <NavbarBrand tag={Link} to="/"><img src={this.props.configurations.logoPicture} className="logo-icon" alt=""></img></NavbarBrand>


        return (
          <header style={headerStyle}>
            {logo}
            <div className="lang-list">
              {(langKeys.length > 1)
                ? langKeys.map((langKey, i) => {
                  let img;

                  try {
                      if (langKey.isDefault) {
                          img = `https://lockercenprodstoacc.blob.core.windows.net/countryflags/${defaultLanguageIcon}.png`;
                      }
                      else {
                          img = require(`../assets/images/flags/${langKey.languageKey}.png`);
                      }
                  } catch {
                    img = `${langKey}.png`;
                  }

                  return (
                      <span onClick={() => { i18n.changeLanguage(langKey.languageKey); }} key={i} title={langKey.isDefault ? defaultLanguageKey: langKey.languageKey}>
                      <img src={img} className="flag-icon" alt=""></img>
                    </span>);
                }) : ""}
            </div>
          </header>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        showPreloader: state.globalReducer.loading,
        configurations : state.globalReducer.configurations
    };
};

export default connect(
    mapStateToProps,
    GlobalActions.messageCreator
    // mapDispatchToProps
)(HeaderComponent);
