import React from 'react'
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { t } from '../../i18n';
import { GlobalState } from '../../models/global-state';
import  * as GlobalActions from '../../redux/global/global.action';
import CSS from "csstype";
import ApiService from "../../services/api.service";

type StateProps =
    GlobalState &
    RouteComponentProps<{}>;

interface State{
    timeoutInterval: any
}

class ThankYouComponent extends React.Component<StateProps,State>{
    componentDidMount(){
        if(this.props.isPreview)
            return;
        this.setState({ timeoutInterval: null });
        let timeoutInterval = setInterval(() => {
            //Redirect to first page to reload data. Improve it.
            var link = ""+ApiService.linkCode;
            window.location.assign(link);
        }, 1000 * 10);

        this.setState({timeoutInterval:timeoutInterval})

    }

    render(){
        let fontStyle: CSS.Properties = {
            color: this.props.configurations.fontColor
        }

        return <div style={fontStyle} className="text-center">
            <h1 style={{paddingTop:'50px'}}>{t("WebLinkThankYouPage_ThankYouPageText")}</h1>
        </div>
    }

    componentWillUnmount(){
        let {timeoutInterval} = this.state;
        clearInterval(timeoutInterval);
     }
}

export default connect(
    (state: any) => Object.assign({}, state.globalReducer),
    GlobalActions.messageCreator
)(ThankYouComponent);
