// @ts-ignore
// const API_BASE_ADDRESS = 'window.env.API_URL';
export default class ApiService {
    static linkCode: string | null;
    static isProcessStarted: boolean;

    static getAll(tenantId: string | null) {
        return this.get(`/api/resources/all/${tenantId}`);
    }

    static order() {
        return this.get(`/api/order/single`);
    }

    static authByCode(code: string) {
        let data = {
            code: code
        };
        return this.post(`/api/auth/by-code`, data);
    }

    static authByDistance(latitude: number, longitude: number) {
        let data = {
            latitude: latitude,
            longitude: longitude
        };
        return this.post(`/api/auth/by-distance`, data);
    }

    static lockBox(boxNo: string) {
        let data = {
            boxNo: boxNo
        };
        return this.postWithTimeout(`/api/terminal/lock-box`, data, 30000);
    }

    static unlockBox(boxNo: string) {
        let data = {
            boxNo: boxNo
        };
        return this.postWithTimeout(`/api/terminal/unlock-box/`, data, 30000);
    }

    static async startCollectProcess() {
        return this.post('/api/order/startCollectProcess', null);
    }

    // static async afterCollectStarted() {
    //     return this.post('/api/order/afterCollectStarted', null);
    // }

    static async finishCollectProcess(hasTimeOuted: Boolean = false) {
        let data = {
            hasTimeOuted: hasTimeOuted
        }
        return this.post('/api/order/finishCollectProcess', data);
    }

    private static post(url: string, data: any) {
        return fetch(url, {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify(data)
        }).then((res) => res.text())
        .then((text) => text.length ? JSON.parse(text) : {})
        .catch((error) => {
            throw error;
        });
    }

    private static get(url: string) {
        return fetch(url, {
            method: 'GET',
            headers: this.headers()
        });
    }

    private static headers() {
        let headers: any = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
        if (this.linkCode)
            headers['Link-Code'] = this.linkCode;

        return headers;
    }

    private static postWithTimeout(url: string, data: any, timeout: number){
        const options = {
            method: 'POST',
            headers: this.headers(),
            body: JSON.stringify(data),
            timeout: timeout
        }
        return this.fetchWithTimeout(url, options);
    }

    private static fetchWithTimeout(url: string, options: any) {
        const { timeout = 30000 } = options;
        
        const controller = new AbortController();
        const timerId = setTimeout(() => controller.abort(), timeout);
      
        return fetch(url, {
          ...options,
          signal: controller.signal  
        }).then((res) => res.text())
        .then((text) => text.length ? JSON.parse(text) : {})
        .catch((error) => {
            throw error;
        })
        .finally(() => clearTimeout(timerId) );
    }
}
