import i18n from "i18next";
import {initReactI18next, Translation} from "react-i18next";
import React from "react";
export const t = (key:string, options:{} = {})=>{
    return(<Translation >{t=>t(key, options)}</Translation>)
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({

        lng: window.navigator.language.slice(0,2),
        fallbackLng:'sv',
        load: 'currentOnly',
        react: {
            wait:true,
            useSuspense:false
        },

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    })
    ;
