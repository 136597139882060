export const SUCCESS_API_CALL = "SUCCESS_API_CALL";
export const LOAD_ORDER_LOADING = 'LOAD_ORDER_LOADING';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER_SUCCESS';
export const LOAD_ORDER_ERROR = 'LOAD_ORDER_ERROR';
export const MANAGE_LOADING = 'MANAGE_LOADING';
export const SHOW_FULL_LOADER = 'SHOW_FULL_LOADER';
export const HIDE_FULL_LOADER = 'HIDE_FULL_LOADER';
export const MANAGE_REGISTER_LOADING = 'MANAGE_REGISTER_LOADING';
export const ERROR_API_CALL = 'ERROR_API_CALL';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const MESSAGE_WARNING = 'MESSAGE_WARNING';
export const MESSAGE_CLEAR = 'MESSAGE_CLEAR';

export const SET_LINK_CODE = 'SET_LINK_CODE';
export const CLEAR_LINK_CODE = 'CLEAR_LINK_CODE';
export const SET_CONFIGURATIONS = 'SET_CONFIGURATIONS';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_AUTH_CONFIGURATIONS = 'SET_AUTH_CONFIGURATIONS';
export const AUTHENTIFICATE_QR_CODE = 'AUTHENTIFICATE_QR_CODE';
export const AUTHENTIFICATE_LOCATION = 'AUTHENTIFICATE_LOCATION';

export const SET_PREVIEW_TENANT_ID = 'SET_PREVIEW_TENANT_ID';

export const SET_IS_DM_INTEGRATION_ENABLED = "SET_IS_DM_INTEGRATION_ENABLED";
// export const SET_IS_HAS_ADDITIONAL_ITEMS_NOTIFICATION_SENT = "SET_IS_HAS_ADDITIONAL_ITEMS_NOTIFICATION_SENT";
// export const SET_IS_PENDING_ORDER_NOTIFICATION_SENT = "SET_IS_PENDING_ORDER_NOTIFICATION_SENT";
export const SET_IS_NOTIFICATION_SENDED = "SET_IS_NOTIFICATION_SENDED";
export const SET_AGE_CONTROL_TYPE = "AGE_CONTROL_TYPE";
