import * as ActionType from "./global.type";
import { MessageTypeEnum } from "../../enums/message-type-enum";
import { Configuration } from "../../models/configuration";
import { AuthConfigurations } from "../../models/auth-configurations";

// export function getStyles(domainID:number, preview:boolean) {
//     return (dispatch:any)=>{
//         ApiService.getStyles(domainID,preview).then(response=>response.json())
//             .then(data=>dispatch({type:SUCCESS_API_CALL,data}))
//     }
// }

// export function getOrder (orderNumber:string) {
//     return (dispatch: any) => {
//         dispatch({type: LOAD_ORDER_LOADING});
//        return Api.getOrderStatus(orderNumber)
//             .then(response => response.json())
//             .then(data => dispatch({type: LOAD_ORDER_SUCCESS, data}))
//             .catch(() => dispatch({type: LOAD_ORDER_ERROR, error: true}))
//     }
// }

export interface SetLoadingAction {type: typeof ActionType.MANAGE_LOADING, data : boolean}
export interface showFullLoaderAction {type: typeof ActionType.SHOW_FULL_LOADER}
export interface hideFullLoaderAction {type: typeof ActionType.HIDE_FULL_LOADER}

export const managePreloader = {
    showLoader: (data: boolean) => ({type: ActionType.MANAGE_LOADING, data: data} as SetLoadingAction),
    showFullLoader: () => ({type: ActionType.SHOW_FULL_LOADER} as showFullLoaderAction),
    hideFullLoader: () => ({type: ActionType.HIDE_FULL_LOADER} as hideFullLoaderAction)

}

export interface SetConfigurationsAction {type: typeof ActionType.SET_CONFIGURATIONS, data:Configuration}
export interface SetAuthConfigurationsAction {type: typeof ActionType.SET_AUTH_CONFIGURATIONS, data:AuthConfigurations}
export interface AuthentificateQrCodeAction {type: typeof ActionType.AUTHENTIFICATE_QR_CODE, data:boolean}
export interface AuthentificateLocationAction {type: typeof ActionType.AUTHENTIFICATE_LOCATION, data:boolean}
export interface SetPreviewTenantIdAction {type: typeof ActionType.SET_PREVIEW_TENANT_ID, data : number | null}
export interface SetTranslationsAction {type: typeof ActionType.SET_TRANSLATIONS, data : number | null}
export interface SetLinkCodeAction {type: typeof ActionType.SET_LINK_CODE, data : string | null}
export interface ClearLinkCodeAction {type: typeof ActionType.CLEAR_LINK_CODE}
export interface SetIsDmIntegrationEnabled {type: typeof ActionType.SET_IS_DM_INTEGRATION_ENABLED, data:boolean}
// export interface SetIsHasAdditionalItemsNotificationSent {type: typeof ActionType.SET_IS_HAS_ADDITIONAL_ITEMS_NOTIFICATION_SENT, data:boolean}
// export interface SetIsPendingOrderNotificationSent {type: typeof ActionType.SET_IS_PENDING_ORDER_NOTIFICATION_SENT, data:boolean}
export interface SetIsNotificationSented {type: typeof ActionType.SET_IS_NOTIFICATION_SENDED, data:boolean}
export interface SetAgeControlType {type: typeof ActionType.SET_AGE_CONTROL_TYPE, data:number}

export const configurationCreator = {
    setLinkCode: (data:string) => ({ type: ActionType.SET_LINK_CODE, data: data } as SetLinkCodeAction),
    clearLinkCode: () => ({ type: ActionType.CLEAR_LINK_CODE } as ClearLinkCodeAction),
    setConfigurations: (data:Configuration) => ({ type: ActionType.SET_CONFIGURATIONS, data: data } as SetConfigurationsAction),
    setAuthConfigurations: (data:AuthConfigurations) => ({ type: ActionType.SET_AUTH_CONFIGURATIONS, data: data } as SetAuthConfigurationsAction),
    authentificateQrCode: (data:boolean) => ({ type: ActionType.AUTHENTIFICATE_QR_CODE, data: data } as AuthentificateQrCodeAction),
    authentificateLocation: (data:boolean) => ({ type: ActionType.AUTHENTIFICATE_LOCATION, data: data } as AuthentificateLocationAction),
    setPreviewTenantId: (data:number | null) => ({ type: ActionType.SET_PREVIEW_TENANT_ID, data: data } as SetPreviewTenantIdAction),
    setTranslations: (data:Object | null) => ({ type: ActionType.SET_TRANSLATIONS, data: data } as SetTranslationsAction),
    setIsDmIntegrationEnabled:(data:boolean) => ({type: ActionType.SET_IS_DM_INTEGRATION_ENABLED, data: data} as SetIsDmIntegrationEnabled),
    // setIsHasAdditionalItemsNotificationSent:(data:boolean) => ({type: ActionType.SET_IS_HAS_ADDITIONAL_ITEMS_NOTIFICATION_SENT, data:data} as SetIsHasAdditionalItemsNotificationSent),
    // setIsPendingOrderNotificationSent:(data:boolean) => ({type: ActionType.SET_IS_PENDING_ORDER_NOTIFICATION_SENT, data:data} as SetIsPendingOrderNotificationSent),
    setIsNotificationSented:(data:boolean) => ({type: ActionType.SET_IS_NOTIFICATION_SENDED, data: data} as SetIsNotificationSented),
    setAgeControlType:(data:number) => ({type: ActionType.SET_AGE_CONTROL_TYPE, data: data} as SetAgeControlType)
};



export interface ShowSuccessMessageAction {type: typeof ActionType.MESSAGE_SUCCESS, message:string, messageType:MessageTypeEnum}
export interface ShowErrorMessageAction {type: typeof ActionType.MESSAGE_ERROR, message:string, messageType:MessageTypeEnum}
export interface ShowWarningMessageAction {type: typeof ActionType.MESSAGE_WARNING, message:string, messageType:MessageTypeEnum}
export interface ClearMessageAction {type: typeof ActionType.MESSAGE_CLEAR}


export const messageCreator = {
    showSuccessMessage: (message:string) => ({ type: ActionType.MESSAGE_SUCCESS, message: message, messageType: MessageTypeEnum.success } as ShowSuccessMessageAction),
    showErrorMessage: (message:string) => ({ type: ActionType.MESSAGE_ERROR, message: message,  messageType: MessageTypeEnum.error } as ShowErrorMessageAction),
    showWarningMessage: (message:string) => ({ type: ActionType.MESSAGE_WARNING, message: message, messageType: MessageTypeEnum.warning } as ShowWarningMessageAction),
    clearMessage: () => ({ type: ActionType.MESSAGE_CLEAR } as ClearMessageAction)
};


